import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../storage/storage.service';
const LNG_KEY = 'SELECTED_LANGUAGE';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  environment = environment;
  selected = '';
  constructor(private translateService: TranslateService, private storageService: StorageService) {}

  setInitialAppLanguage(){
    //const language = this.translateService.getBrowserLang();
    const language = this.environment?.config?.language?.value;
    this.translateService.setDefaultLang(language);

    //for the case tha user can switch languages
    this.storageService.getLanguage().then(val =>{
      if (val){
        this.setLanguage(val);
        this.selected = val;
      }
    });
  }

  //for the case tha user can switch languages
  getLanguages(){
    return [
      {text: 'English', value: 'en'},
      {text: 'Ελληνικά', value: 'el'},
    ];
  }

  setLanguage(lng){
    this.translateService.use(lng);
    this.selected = lng;
    this.storageService.setLanguage(lng);
  }
}
