import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ScannerComponent } from './scanner/scanner.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { MenuComponent } from '../view/menu/menu.component';
import { RouterModule } from '@angular/router';
import { CouponConfirmModalComponent } from './coupon-confirm-modal/coupon-confirm-modal.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	imports: [
		CommonModule,
		IonicModule,
		ZXingScannerModule,
		RouterModule,
    TranslateModule
	],
	declarations: [
		ScannerComponent,
		MenuComponent,
		CouponConfirmModalComponent
	],
	exports: [
		ScannerComponent,
		MenuComponent,
		CouponConfirmModalComponent
	],
	entryComponents: [
		ScannerComponent,
		MenuComponent,
		CouponConfirmModalComponent
	]
})

export class SharedModule { }
