import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { MemberInfo, MemberInfoCodes } from 'src/app/models/json.model';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  mInfo: MemberInfo = {
    lastName : '',
    firstName : '',
    mobileNumber: '',
    cardNumber: '',
    points: '',
    coupons: '',
    memberCategory: ''
  };

  mInfoCodes: MemberInfoCodes = {
    cQCcode : '',
    mQCcode : ''
  };

  constructor() { }

  async setToken(token: any) {
    const decodedToken = this.getDecodedAccessToken(token);

    await Storage.set({
      key: 'token',
      value: token
    });

    await Storage.set({
      key: 'token_expires_at',
      value: decodedToken.exp
    });

    await Storage.set({
      key: 'username',
      value: decodedToken.data.username
    });
  }

  async getToken() {
    return await Storage.get({ key: 'token' }).then((token: {value: any}) => token);
  }

  async getTokenExpiration() {
    return await Storage.get({ key: 'token_expires_at' }).then((token_expires_at: {value: any}) => token_expires_at);
  }

  async removeToken() {
    await Storage.remove({key: 'token'});
    await Storage.remove({key: 'firstName'});
    await Storage.remove({key: 'lastName'});
    await Storage.remove({key: 'mobileNumber'});
    await Storage.remove({key: 'cardNumber'});
    await Storage.remove({key: 'memberCategory'});
    await Storage.remove({key: 'cQCcode'});
    await Storage.remove({key: 'mQCcode'});
    await Storage.remove({key: 'points'});
    await Storage.remove({key: 'coupons'});
    await Storage.remove({key: 'allCoupons'});
    await Storage.remove({key: 'selected_language'});
  }

  private getDecodedAccessToken(token: string): any {

    try{
        return jwt_decode(token);
    }
    catch(Error){
        return null;
    }
  }

  async clearStorage(){
    await Storage.clear();
  }

  async setStoreInit(storeCode: string, storeName: string, storeAddress: string) {
    await Storage.set({
      key: 'storeCode',
      value: storeCode
    });
    await Storage.set({
      key: 'storeName',
      value: storeName
    });
    await Storage.set({
      key: 'storeAddress',
      value: storeAddress
    });

    await Storage.set({
      key: 'store',
      value: JSON.stringify({code: storeCode, name: storeName, address: storeAddress})
    });
  }

  async getStoreCode() {
    return await Storage.get({ key: 'storeCode' }).then((storeCode: {value: any}) => storeCode);
  }

  async getStoreName() {
    return await Storage.get({ key: 'storeName' }).then((storeName: {value: any}) => storeName);
  }

  async getStoreAddress() {
    return await Storage.get({ key: 'storeAddress' }).then((storeAddress: {value: any}) => storeAddress);
  }

  async getStore() {
    return await Storage.get({ key: 'store' }).then((store: {value: any}) => store);
  }

  // eslint-disable-next-line max-len
  async setMemberInfo(firstName: string, lastName: string, mobileNumber: string, cardNumber: string, points: string, coupons: string, allCoupons: string, memberCategory: string, cQCcode: string, mQCcode: string, updatePersonalInfo: string) {
    await Storage.set({
      key: 'firstName',
      value: firstName
    });
    await Storage.set({
      key: 'lastName',
      value: lastName
    });
    await Storage.set({
      key: 'mobileNumber',
      value: mobileNumber
    });
    await Storage.set({
      key: 'cardNumber',
      value: cardNumber
    });
    await Storage.set({
      key: 'points',
      value: points
    });
    await Storage.set({
      key: 'coupons',
      value: coupons
    });
    await Storage.set({
      key: 'allCoupons',
      value: allCoupons
    });
    await Storage.set({
      key: 'memberCategory',
      value: memberCategory
    });
    await Storage.set({
      key: 'cQCcode',
      value: cQCcode
    });
    await Storage.set({
      key: 'mQCcode',
      value: mQCcode
    });
    await Storage.set({
      key: 'updatePersonalInfo',
      value: updatePersonalInfo
    });
  }

  async getMemberInfo() {
    this.mInfo.firstName = await Storage.get({ key: 'firstName' }).then((firstName: { value: string }) => firstName.value);
    this.mInfo.lastName = await Storage.get({ key: 'lastName' }).then((lastName: { value: string }) => lastName.value);
    this.mInfo.mobileNumber = await Storage.get({ key: 'mobileNumber' }).then((mobileNumber: { value: string }) => mobileNumber.value);
    this.mInfo.cardNumber = await Storage.get({ key: 'cardNumber' }).then((cardNumber: { value: string }) => cardNumber.value);
    this.mInfo.points = await Storage.get({ key: 'points' }).then((points: { value: string }) => points.value);
    // eslint-disable-next-line max-len
    this.mInfo.memberCategory = await Storage.get({ key: 'memberCategory' }).then((memberCategory: { value: string }) => memberCategory.value);
    return this.mInfo;
  }

  async getMemberInfoCodes() {
    this.mInfoCodes.cQCcode = await Storage.get({ key: 'cQCcode' }).then((cQCcode: { value: string }) => cQCcode.value);
    this.mInfoCodes.mQCcode = await Storage.get({ key: 'mQCcode' }).then((mQCcode: { value: string }) => mQCcode.value);
    return this.mInfoCodes;
  }

  async getUpdatePersonalInfo() {
    return await Storage.get({ key: 'updatePersonalInfo' }).then((updatePersonalInfo: {value: any}) => updatePersonalInfo);
  }

  async getCoupons() {
    return await Storage.get({ key: 'coupons' }).then((coupons: {value: any}) => coupons);
  }

  async getAllCoupons() {
    return await Storage.get({ key: 'allCoupons' }).then((allCoupons: {value: any}) => allCoupons);
  }


  async getMemberPoints() {
    return await Storage.get({ key: 'points' }).then((points: {value: any}) => points);
  }

  async setMemberPoints(points: string) {
    await Storage.set({
      key: 'points',
      value: points
    });
  }

  async setCouponSetsData(couponsets: string) {
    await Storage.set({
      key: 'couponsets',
      value: couponsets
    });
  }

  async getCouponSetsData() {
    return await Storage.get({ key: 'couponsets' }).then((couponsets: {value: any}) => couponsets);
  }

  async setLanguage(language: string) {
    await Storage.set({
      key: 'selected_language',
      value: language
    });
  }

  async getLanguage() {
    return await Storage.get({ key: 'selected_language' }).then((language: {value: any}) => language.value);
  }

}
