// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  endpointQC: 'https://hellenica-uat.qivos.net/qc-api/v1.0',
  authQC: 'https://hellenica-uat.qivos.net/rest-ci-hellenica-UAT/rest',
  ssoUrl: 'https://api-staging.qivos.net',
  basic: 'Basic YWRtaW46OHdDOGR1Y0FZeHVVU2tGQUpBczU=',
  endpointQivosApi: 'https://api-staging.qivos.net/',
  endpointIDP: 'https://idp.ci-uat.net',
  config: {
    allowRegistration: false,
    allowCoupons: true,
    reports:{
      allow: false,
      burnedCoupons: {
        allow: false
      }
    },
    transaction: {
      allow: true,
      enterAmount: {
        allow: false
      },
      scanReceipt:{
        allow: false
      },
      searchProduct:{
        allow: true
      }
    },
    redemption: {
      allow: false
    },
    coupons: {
      allow: true,
      burnCoupon: {
        allow: false
      }
    },
    language:{
      allowSwitch: false,
      value: 'el'
    }
  },
  client: 'hellenica'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
